import { FacebookIcon, InstagramIcon, LogoIcon } from "assets";
import classNames from "classnames";
import { Mail, MapPin, Phone } from "react-feather";
import { useNavigate } from "react-router";
import { routeConstant } from "shared/routes/routeConstant";
import styles from "./style.module.scss";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={classNames(styles.footerContainer, "py-5")}>
        <div
          className={classNames(
            styles.customContainer,
            "d-flex flex-column flex-lg-row"
          )}
        >
          <div
            className={classNames(
              "col-lg-4 col-12 d-flex flex-column align-items-start px-3 p-lg-0 m-0"
            )}
          >
            <LogoIcon
              className={classNames(
                styles.logoIcon,
                "align-self-center align-self-lg-start"
              )}
            />
            <label className={classNames(styles.aboutLabel, "mt-4")}>
              We are manufacturers and exporters of high-quality Sportswear,
              Fitness wear, and Casual wear. Our company produces custom designs
              and sizes as per customer’s requirements.
            </label>
          </div>
          <div
            className={classNames(
              "col-lg-8 col-12 p-0 mt-5 m-lg-0 d-flex flex-sm-row flex-column justify-content-center"
            )}
          >
            <div className="col-md-5 col-sm-6 col-12 d-flex flex-column px-3 px-lg-0">
              <label className={classNames(styles.listTitle)}>Navigation</label>
              <label
                className={classNames(styles.listItem, "mt-4")}
                onClick={() => {
                  navigate(routeConstant.home.path);
                }}
              >
                Home
              </label>
              <label
                className={classNames(styles.listItem, "mt-1")}
                onClick={() => {
                  navigate(routeConstant.products.path);
                }}
              >
                Products
              </label>
              <label
                className={classNames(styles.listItem, "mt-1")}
                onClick={() => {
                  navigate(routeConstant.about.path);
                }}
              >
                About Us
              </label>
              <label
                className={classNames(styles.listItem, "mt-1")}
                onClick={() => {
                  navigate(routeConstant.contact.path);
                }}
              >
                Contact Us
              </label>
            </div>
            <div className="col-md-5 col-sm-6 col-12 px-3 px-sm-0 mt-5 mt-ms-0 d-flex flex-column">
              <label className={classNames(styles.listTitle)}>Visit Us</label>
              <label className={classNames(styles.listItem, "mt-3")}>
                <Phone className={classNames(styles.listIcon)} />
                +44 7349444010
              </label>
              <label className={classNames(styles.listItem, "mt-3")}>
                <Phone className={classNames(styles.listIcon)} /> +92 309
                0009101
              </label>
              <label className={classNames(styles.listItem, "mt-3")}>
                <Mail className={classNames(styles.listIcon)} />{" "}
                silverskyapparel@gmail.com
              </label>
              <label className={classNames(styles.listItem, "mt-3")}>
                <MapPin className={classNames(styles.listIcon)} /> Hagley Road
                West, OldBury UK B68 0NR
              </label>
              <label className={classNames(styles.listItem, "mt-3")}>
                <MapPin className={classNames(styles.listIcon)} /> Rasool pura
                Sambriyal, Sialkot, 51070
              </label>
            </div>
          </div>
        </div>
        <div className={classNames(styles.customContainer, "w-100")}>
          <div
            className={classNames(
              "d-flex align-items-center justify-content-md-start justify-content-center mt-4 gap-4"
            )}
          >
            <FacebookIcon
              className={classNames(styles.socialIcon)}
              role={"button"}
              onClick={() =>
                window.parent.open(
                  "https://www.facebook.com/profile.php?id=100063715011493&mibextid=ZbWKwL"
                )
              }
            />
            <InstagramIcon
              className={classNames(styles.socialIcon)}
              role={"button"}
              onClick={() =>
                window.parent.open(
                  "https://instagram.com/silversky_apparel?igshid=OTJlNzQ0NWM="
                )
              }
            />
          </div>
        </div>
      </div>
      <div className={classNames(styles.footerbtm, "py-1")}>
        <label className={styles.footerBtmLabel}>
          ©2023 ALL RIGHTS SILVER SKY APPAREL
        </label>
      </div>
    </>
  );
};

export default Footer;
